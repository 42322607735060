<template>
	<path id="Vector" d="M6 159.74L43.3699 85H143.114L180.484 159.74L143.114 234.48H43.3699L6 159.74Z"
            fill="#D4A168" />
        <path id="Vector (Stroke)" fill-rule="evenodd" clip-rule="evenodd"
            d="M39.8699 80H145.794L185.664 159.74L145.794 239.48H39.8699L0 159.74L39.8699 80ZM46.0503 90L11.1803 159.74L46.0503 229.48H139.614L174.484 159.74L139.614 90H46.0503Z"
            fill="#D4A168" />
        <path id="Vector_2" d="M279.59 318.74L316.96 244H416.704L454.074 318.74L416.704 393.48H316.96L279.59 318.74Z"
            fill="#D4A168" />
        <path id="Vector_3" d="M831 318.74L868.37 244H968.114L1005.48 318.74L968.114 393.48H868.37L831 318.74Z"
            fill="#D4A168" />
        <path id="Vector_4" d="M831 466.74L868.37 392H968.114L1005.48 466.74L968.114 541.48H868.37L831 466.74Z"
            fill="#D4A168" />
        <path id="Vector_5" d="M557 318.74L594.37 244H694.114L731.484 318.74L694.114 393.48H594.37L557 318.74Z"
            fill="#D4A168" />
        <path id="Vector_6" d="M1107 463.74L1144.37 389H1244.11L1281.48 463.74L1244.11 538.48H1144.37L1107 463.74Z"
            fill="#D4A168" />
        <path id="Vector (Stroke)_2" fill-rule="evenodd" clip-rule="evenodd"
            d="M313.87 239H419.794L459.664 318.74L419.794 398.48H313.87L274 318.74L313.87 239ZM320.05 249L285.18 318.74L320.05 388.48H413.614L448.484 318.74L413.614 249H320.05Z"
            fill="#D4A168" />
        <path id="Vector (Stroke)_3" fill-rule="evenodd" clip-rule="evenodd"
            d="M864.87 239H970.794L1010.66 318.74L970.794 398.48H864.87L825 318.74L864.87 239ZM871.05 249L836.18 318.74L871.05 388.48H964.614L999.484 318.74L964.614 249H871.05Z"
            fill="#D4A168" />
        <path id="Vector (Stroke)_4" fill-rule="evenodd" clip-rule="evenodd"
            d="M864.87 387H970.794L1010.66 466.74L970.794 546.48H864.87L825 466.74L864.87 387ZM871.05 397L836.18 466.74L871.05 536.48H964.614L999.484 466.74L964.614 397H871.05Z"
            fill="#D4A168" />
        <path id="Vector (Stroke)_5" fill-rule="evenodd" clip-rule="evenodd"
            d="M590.87 239H696.794L736.664 318.74L696.794 398.48H590.87L551 318.74L590.87 239ZM597.05 249L562.18 318.74L597.05 388.48H690.614L725.484 318.74L690.614 249H597.05Z"
            fill="#D4A168" />
        <path id="Vector (Stroke)_6" fill-rule="evenodd" clip-rule="evenodd"
            d="M1140.87 384H1246.79L1286.66 463.74L1246.79 543.48H1140.87L1101 463.74L1140.87 384ZM1147.05 394L1112.18 463.74L1147.05 533.48H1240.61L1275.48 463.74L1240.61 394H1147.05Z"
            fill="#D4A168" />
        <path id="Vector_7" d="M142 79.74L179.37 5H279.114L316.484 79.74L279.114 154.48H179.37L142 79.74Z"
            fill="#D4A168" />
        <path id="Vector_8" d="M695 79.74L732.37 5H832.114L869.484 79.74L832.114 154.48H732.37L695 79.74Z"
            fill="#D4A168" />
        <path id="Vector_9" d="M420 79.74L457.37 5H557.114L594.484 79.74L557.114 154.48H457.37L420 79.74Z"
            fill="#D4A168" />
        <path id="Vector_10" d="M969 228.74L1006.37 154H1106.11L1143.48 228.74L1106.11 303.48H1006.37L969 228.74Z"
            fill="#D4A168" />
        <path id="Vector (Stroke)_7" fill-rule="evenodd" clip-rule="evenodd"
            d="M175.87 0H281.794L321.664 79.74L281.794 159.48H175.87L136 79.74L175.87 0ZM182.05 10L147.18 79.74L182.05 149.48H275.614L310.484 79.74L275.614 10H182.05Z"
            fill="#D4A168" />
        <path id="Vector (Stroke)_8" fill-rule="evenodd" clip-rule="evenodd"
            d="M728.87 0H834.794L874.664 79.74L834.794 159.48H728.87L689 79.74L728.87 0ZM735.05 10L700.18 79.74L735.05 149.48H828.614L863.484 79.74L828.614 10H735.05Z"
            fill="#D4A168" />
        <path id="Vector (Stroke)_9" fill-rule="evenodd" clip-rule="evenodd"
            d="M453.87 0H559.794L599.664 79.74L559.794 159.48H453.87L414 79.74L453.87 0ZM460.05 10L425.18 79.74L460.05 149.48H553.614L588.484 79.74L553.614 10H460.05Z"
            fill="#D4A168" />
        <path id="Vector (Stroke)_10" fill-rule="evenodd" clip-rule="evenodd"
            d="M1002.87 149H1108.79L1148.66 228.74L1108.79 308.48H1002.87L963 228.74L1002.87 149ZM1009.05 159L974.18 228.74L1009.05 298.48H1102.61L1137.48 228.74L1102.61 159H1009.05Z"
            fill="#D4A168" />
        <path id="Vector_11" d="M6 318.74L43.3699 244H143.114L180.484 318.74L143.114 393.48H43.3699L6 318.74Z"
            fill="#D4A168" />
        <path id="Vector (Stroke)_11" fill-rule="evenodd" clip-rule="evenodd"
            d="M39.8699 239H145.794L185.664 318.74L145.794 398.48H39.8699L0 318.74L39.8699 239ZM46.0503 249L11.1803 318.74L46.0503 388.48H139.614L174.484 318.74L139.614 249H46.0503Z"
            fill="#D4A168" />
        <path id="Vector_12" d="M280 474.74L317.37 400H417.114L454.484 474.74L417.114 549.48H317.37L280 474.74Z"
            fill="#D4A168" />
        <path id="Vector_13" d="M969 79.74L1006.37 5H1106.11L1143.48 79.74L1106.11 154.48H1006.37L969 79.74Z"
            fill="#D4A168" />
        <path id="Vector_14" d="M557 474.74L594.37 400H694.114L731.484 474.74L694.114 549.48H594.37L557 474.74Z"
            fill="#D4A168" />
        <path id="Vector (Stroke)_12" fill-rule="evenodd" clip-rule="evenodd"
            d="M313.87 395H419.794L459.664 474.74L419.794 554.48H313.87L274 474.74L313.87 395ZM320.05 405L285.18 474.74L320.05 544.48H413.614L448.484 474.74L413.614 405H320.05Z"
            fill="#D4A168" />
        <path id="Vector (Stroke)_13" fill-rule="evenodd" clip-rule="evenodd"
            d="M1002.87 0H1108.79L1148.66 79.74L1108.79 159.48H1002.87L963 79.74L1002.87 0ZM1009.05 10L974.18 79.74L1009.05 149.48H1102.61L1137.48 79.74L1102.61 10H1009.05Z"
            fill="#D4A168" />
        <path id="Vector (Stroke)_14" fill-rule="evenodd" clip-rule="evenodd"
            d="M590.87 395H696.794L736.664 474.74L696.794 554.48H590.87L551 474.74L590.87 395ZM597.05 405L562.18 474.74L597.05 544.48H690.614L725.484 474.74L690.614 405H597.05Z"
            fill="#D4A168" />
        <path id="Vector_15" d="M142 238.74L179.37 164H279.114L316.484 238.74L279.114 313.48H179.37L142 238.74Z"
            fill="#D4A168" />
        <path id="Vector_16" d="M695 238.74L732.37 164H832.114L869.484 238.74L832.114 313.48H732.37L695 238.74Z"
            fill="#D4A168" />
        <path id="Vector_17" d="M420 238.74L457.37 164H557.114L594.484 238.74L557.114 313.48H457.37L420 238.74Z"
            fill="#D4A168" />
        <path id="Vector_18" d="M969 387.74L1006.37 313H1106.11L1143.48 387.74L1106.11 462.48H1006.37L969 387.74Z"
            fill="#D4A168" />
        <path id="Vector (Stroke)_15" fill-rule="evenodd" clip-rule="evenodd"
            d="M175.87 159H281.794L321.664 238.74L281.794 318.48H175.87L136 238.74L175.87 159ZM182.05 169L147.18 238.74L182.05 308.48H275.614L310.484 238.74L275.614 169H182.05Z"
            fill="#D4A168" />
        <path id="Vector (Stroke)_16" fill-rule="evenodd" clip-rule="evenodd"
            d="M728.87 159H834.794L874.664 238.74L834.794 318.48H728.87L689 238.74L728.87 159ZM735.05 169L700.18 238.74L735.05 308.48H828.614L863.484 238.74L828.614 169H735.05Z"
            fill="#D4A168" />
        <path id="Vector (Stroke)_17" fill-rule="evenodd" clip-rule="evenodd"
            d="M453.87 159H559.794L599.664 238.74L559.794 318.48H453.87L414 238.74L453.87 159ZM460.05 169L425.18 238.74L460.05 308.48H553.614L588.484 238.74L553.614 169H460.05Z"
            fill="#D4A168" />
        <path id="Vector (Stroke)_18" fill-rule="evenodd" clip-rule="evenodd"
            d="M1002.87 308H1108.79L1148.66 387.74L1108.79 467.48H1002.87L963 387.74L1002.87 308ZM1009.05 318L974.18 387.74L1009.05 457.48H1102.61L1137.48 387.74L1102.61 318H1009.05Z"
            fill="#D4A168" />
        <path id="Vector_19" d="M6 475.74L43.3699 401H143.114L180.484 475.74L143.114 550.48H43.3699L6 475.74Z"
            fill="#D4A168" />
        <path id="Vector (Stroke)_19" fill-rule="evenodd" clip-rule="evenodd"
            d="M39.8699 396H145.794L185.664 475.74L145.794 555.48H39.8699L0 475.74L39.8699 396ZM46.0503 406L11.1803 475.74L46.0503 545.48H139.614L174.484 475.74L139.614 406H46.0503Z"
            fill="#D4A168" />
        <path id="Vector_20" d="M142 395.74L179.37 321H279.114L316.484 395.74L279.114 470.48H179.37L142 395.74Z"
            fill="#D4A168" />
        <path id="Vector_21" d="M695 395.74L732.37 321H832.114L869.484 395.74L832.114 470.48H732.37L695 395.74Z"
            fill="#D4A168" />
        <path id="Vector_22" d="M420 395.74L457.37 321H557.114L594.484 395.74L557.114 470.48H457.37L420 395.74Z"
            fill="#D4A168" />
        <path id="Vector_23" d="M1109 146.74L1146.37 72H1246.11L1283.48 146.74L1246.11 221.48H1146.37L1109 146.74Z"
            fill="#D4A168" />
        <path id="Vector (Stroke)_20" fill-rule="evenodd" clip-rule="evenodd"
            d="M175.87 316H281.794L321.664 395.74L281.794 475.48H175.87L136 395.74L175.87 316ZM182.05 326L147.18 395.74L182.05 465.48H275.614L310.484 395.74L275.614 326H182.05Z"
            fill="#D4A168" />
        <path id="Vector (Stroke)_21" fill-rule="evenodd" clip-rule="evenodd"
            d="M728.87 316H834.794L874.664 395.74L834.794 475.48H728.87L689 395.74L728.87 316ZM735.05 326L700.18 395.74L735.05 465.48H828.614L863.484 395.74L828.614 326H735.05Z"
            fill="#D4A168" />
        <path id="Vector (Stroke)_22" fill-rule="evenodd" clip-rule="evenodd"
            d="M453.87 316H559.794L599.664 395.74L559.794 475.48H453.87L414 395.74L453.87 316ZM460.05 326L425.18 395.74L460.05 465.48H553.614L588.484 395.74L553.614 326H460.05Z"
            fill="#D4A168" />
        <path id="Vector (Stroke)_23" fill-rule="evenodd" clip-rule="evenodd"
            d="M1142.87 67H1248.79L1288.66 146.74L1248.79 226.48H1142.87L1103 146.74L1142.87 67ZM1149.05 77L1114.18 146.74L1149.05 216.48H1242.61L1277.48 146.74L1242.61 77H1149.05Z"
            fill="#D4A168" />
        <path id="Vector_24" d="M280 159.74L317.37 85H417.114L454.484 159.74L417.114 234.48H317.37L280 159.74Z"
            fill="#D4A168" />
        <path id="Vector_25" d="M831 159.74L868.37 85H968.114L1005.48 159.74L968.114 234.48H868.37L831 159.74Z"
            fill="#D4A168" />
        <path id="Vector_26" d="M557 159.74L594.37 85H694.114L731.484 159.74L694.114 234.48H594.37L557 159.74Z"
            fill="#D4A168" />
        <path id="Vector_27" d="M1107 304.74L1144.37 230H1244.11L1281.48 304.74L1244.11 379.48H1144.37L1107 304.74Z"
            fill="#D4A168" />
        <path id="Vector (Stroke)_24" fill-rule="evenodd" clip-rule="evenodd"
            d="M313.87 80H419.794L459.664 159.74L419.794 239.48H313.87L274 159.74L313.87 80ZM320.05 90L285.18 159.74L320.05 229.48H413.614L448.484 159.74L413.614 90H320.05Z"
            fill="#D4A168" />
        <path id="Vector (Stroke)_25" fill-rule="evenodd" clip-rule="evenodd"
            d="M864.87 80H970.794L1010.66 159.74L970.794 239.48H864.87L825 159.74L864.87 80ZM871.05 90L836.18 159.74L871.05 229.48H964.614L999.484 159.74L964.614 90H871.05Z"
            fill="#D4A168" />
        <path id="Vector (Stroke)_26" fill-rule="evenodd" clip-rule="evenodd"
            d="M590.87 80H696.794L736.664 159.74L696.794 239.48H590.87L551 159.74L590.87 80ZM597.05 90L562.18 159.74L597.05 229.48H690.614L725.484 159.74L690.614 90H597.05Z"
            fill="#D4A168" />
        <path id="Vector (Stroke)_27" fill-rule="evenodd" clip-rule="evenodd"
            d="M1140.87 225H1246.79L1286.66 304.74L1246.79 384.48H1140.87L1101 304.74L1140.87 225ZM1147.05 235L1112.18 304.74L1147.05 374.48H1240.61L1275.48 304.74L1240.61 235H1147.05Z"
            fill="#D4A168" />
</template>